.f3-widget-paginator{
  margin-left: 0;
  text-align: right;
  li{
    list-style: none;
    display: inline;
    &.current{
      color: #fff;
      background: #524E9C;
      padding: 0 4px;

    }
  }

}

.imgNewsDet{
  border: none;
  padding: 0!important;
}