.powermail_fieldwrap_type_check{
  label.powermail_label {
    width: 100%;
    float: none;
    clear: left;
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: .5rem;
  }
}

label[for="powermail_field_datenschutz"],label[for="powermail_field_datenschutz_01"]{
  display: none;
}

label[for="powermail_field_kontaktaufnahme"]{
  font-size: 1rem;
}


.tx-powermail{
  .btn-primary,.btn-warning {
    border-style: solid;
    float: right;
    border-width: 0;
    cursor: pointer;
    font-family: "Helvetica Neue","Helvetica",Helvetica,Arial,sans-serif !important;
    font-weight: normal;
    line-height: normal;
    margin: 0 0 1.11111rem;
    position: relative;
    text-decoration: none;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
    display: inline-block;
    padding: 0.5rem 1.4rem;
    font-size: 0.88889rem;
    background-color: #524E9C!important;
    border-color: #524E9C!important;
    color: #FFFFFF;
    transition: background-color 300ms ease-out;
  }

  label {
    font-size: 0.875rem;
    color: #4d4d4d;
    cursor: pointer;
    display: flex;
    font-weight: normal;
    line-height: 1.5;
    margin-bottom: 0;
    gap: .5rem;


  }
  input[type="checkbox"]{
    margin: 0;
  }
  select{

  }
}

.powermail_check_inner input[type="checkbox"] {
  vertical-align: middle;
}

@media only screen and (min-width: 1046px){
  .powermail_fieldwrap_type_country {
    label{
      width: 30%;
      float: left;
    }
    select.powermail_country, select.powermail_country{
      width: 70%;
      float: right;
    }
  }
}

#loadingMessage {
  display: none;
  color: #ff0000;
  font-size: 16px;
  margin-top: 10px;
}


.powermail_fieldwrap_type_captcha{
  label{
    display: inline;
  }

}
 .powermail_fieldwrap_type_captcha{
   label{
     .mandatory {
       position: relative;
       left:5px;
     }
   }
 }

.powermail_form_5{
  .powermail_check_inner{
    display: flex;
    margin-bottom: 5px;
  }
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
  display: none;
}

.powermail_check_inner_1{
  display:flex;

}